export default {
    "en": {
        "(and {count} more error)": "(and {count} more error)",
        "(and {count} more errors)": "(and {count} more errors)",
        "*Required fields": "*Required fields",
        "404 - Page not found": "404 - Page not found",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{count} characters": "{count} characters",
        "Active": "Active",
        "Addition": "Addition",
        "Address details": "Address details",
        "All news items": "All news items",
        "All questions": "All questions",
        "All rights reserved.": "All rights reserved.",
        "alt text": "alt text",
        "Amount": "Amount",
        "answer": "answer",
        "Apply": "Apply",
        "Are you the": "Are you the",
        "Are you the one we are looking for?": "Are you the one we are looking for?",
        "Arrow": "Arrow",
        "Back to home": "Back to home",
        "Become a customer?": "Become a customer?",
        "Button label": "Button label",
        "Card title": "Card title",
        "Choose file": "Choose file",
        "CleanShopping (Headquarters)": "CleanShopping (Headquarters)",
        "CleanShopping North": "CleanShopping North",
        "CleanShopping South (Headquarters)": "CleanShopping South (Headquarters)",
        "CleanShopping ©": "CleanShopping ©",
        "Clients": "Clients",
        "Company data": "Company data",
        "Company name *": "Company name *",
        "Confirmation {source}": "Confirmation {source}",
        "Confirmation Request Offer": "Confirmation Request Offer",
        "Contact information": "Contact information",
        "Content": "Content",
        "Country *": "Country *",
        "Description": "Description",
        "description": "description",
        "Discount": "Discount",
        "Discount active": "Discount active",
        "Download leaflet": "Download leaflet",
        "Do you have a question or request? Feel free to contact us so that our team can help you. Please fill out the quote form below, so we can send you an appropriate price right away.": "Do you have a question or request? Feel free to contact us so that our team can help you. Please fill out the quote form below, so we can send you an appropriate price right away.",
        "E-mail *": "E-mail *",
        "End": "End",
        "Envelope icon": "Envelope icon",
        "Excerpt": "Excerpt",
        "excerpt": "excerpt",
        "External URL": "External URL",
        "Facebook icon": "Facebook icon",
        "Faq": "Faq",
        "Forbidden": "Forbidden",
        "Go to page {page}": "Go to page {page}",
        "Go to the Terms and Conditions page": "Go to the Terms and Conditions page",
        "Go to the Terms and Privacy declaration page": "Go to the Terms and Privacy declaration page",
        "Gum removal": "Gum removal",
        "Hello!": "Hello!",
        "Hits": "Hits",
        "hoeei": "hoeei",
        "Home": "Home",
        "House number *": "House number *",
        "Hygiene station": "Hygiene station",
        "Hygiepole": "Hygiepole",
        "Hyiepole": "Hyiepole",
        "I agree with the terms and conditions*": "I agree with the terms and conditions*",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "If enabled, and no image is uploaded, the banner from the homepage will be shown.",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:",
        "If you did not create an account, no further action is required.": "If you did not create an account, no further action is required.",
        "If you did not request a password reset, no further action is required.": "If you did not request a password reset, no further action is required.",
        "Image": "Image",
        "Image {attribute}": "Image {attribute}",
        "Image for news card. Pageheader image can be edited on the page itself.": "Image for news card. Pageheader image can be edited on the page itself.",
        "Image for vacancy card": "Image for vacancy card",
        "Image impression": "Image impression",
        "Image impression of cleanShopping": "Image impression of cleanShopping",
        "Image of hygiepole": "Image of hygiepole",
        "Image of repairs and maintance": "Image of repairs and maintance",
        "Index Page": "Index Page",
        "Instagram icon": "Instagram icon",
        "It is recommended to keep the {attribute} between {min} and {max} characters long": "It is recommended to keep the {attribute} between {min} and {max} characters long",
        "Leave empty for no end date": "Leave empty for no end date",
        "Leave empty to use parent details": "Leave empty to use parent details",
        "LinkedIn icon": "LinkedIn icon",
        "Login": "Login",
        "Logout": "Logout",
        "Made by:": "Made by:",
        "Make a choice ...": "Make a choice ...",
        "Message *": "Message *",
        "minus icon": "minus icon",
        "Mobile car wash": "Mobile car wash",
        "Motivation *": "Motivation *",
        "name": "name",
        "Name *": "Name *",
        "Netherlands": "Netherlands",
        "News": "News",
        " news items": "news items",
        "news items": "news items",
        "New URL": "New URL",
        "Not Found": "Not Found",
        "Number of cleanings per year": "Number of cleanings per year",
        "Number of cleanings per year*": "Number of cleanings per year*",
        "Number of dollies": "Number of dollies",
        "Number of dollies *": "Number of dollies *",
        "Number of shopping baskets": "Number of shopping baskets",
        "Number of shopping baskets *": "Number of shopping baskets *",
        "Number of shopping carts": "Number of shopping carts",
        "Number of shopping carts *": "Number of shopping carts *",
        "Number of trolley parking(park box)": "Number of trolley parking(park box)",
        "Number of trolley parking(park box) *": "Number of trolley parking(park box) *",
        "Number of trolleys(baskets)": "Number of trolleys(baskets)",
        "Number of trolleys(baskets) *": "Number of trolleys(baskets) *",
        "of": "of",
        "Old URL": "Old URL",
        "Oops, this page was not found": "Oops, this page was not found",
        "Other questions and/or comments": "Other questions and/or comments",
        "Page (within website)": "Page (within website)",
        "Page Expired": "Page Expired",
        "Pages": "Pages",
        "Pagination Navigation": "Pagination Navigation",
        "Parent category": "Parent category",
        "Paste in here only the Youtube ID. That id the ID what you see in the url in the browser after https://www.youtube.com/watch?v=": "Paste in here only the Youtube ID. That id the ID what you see in the url in the browser after https://www.youtube.com/watch?v=",
        "Perform maintenance and repairs": "Perform maintenance and repairs",
        "Permanent": "Permanent",
        "Phone *": "Phone *",
        "Phone icon": "Phone icon",
        "Place": "Place",
        "Place *": "Place *",
        "Please click the button below to verify your email address.": "Please click the button below to verify your email address.",
        "plus icon": "plus icon",
        "Postal code *": "Postal code *",
        "Privacy declaration": "Privacy declaration",
        "Published Date": "Published Date",
        "question": "question",
        "Regards": "Regards",
        "Register": "Register",
        "Related articles": "Related articles",
        "Remove chewing gum": "Remove chewing gum",
        "Repairs and maintenance": "Repairs and maintenance",
        "Request an offer": "Request an offer",
        "Request a quote direclty?": "Request a quote direclty?",
        "Request quote": "Request quote",
        "Reset Password": "Reset Password",
        "Reset Password Notification": "Reset Password Notification",
        "results": "results",
        "SEO title": "SEO title",
        "Server Error": "Server Error",
        "Service Unavailable": "Service Unavailable",
        "Showing": "Showing",
        "Show Request quote and download leaflet buttons": "Show Request quote and download leaflet buttons",
        "Slug": "Slug",
        "Socials": "Socials",
        "Start": "Start",
        "Street name *": "Street name *",
        "Submit": "Submit",
        "Terms and Conditions": "Terms and Conditions",
        "Text": "Text",
        "Thank you for your message!": "Thank you for your message!",
        "Thank you for your Quote Request": "Thank you for your Quote Request",
        "The {attribute} must contain at least one letter.": "The {attribute} must contain at least one letter.",
        "The {attribute} must contain at least one number.": "The {attribute} must contain at least one number.",
        "The {attribute} must contain at least one symbol.": "The {attribute} must contain at least one symbol.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "The {attribute} must contain at least one uppercase and one lowercase letter.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.",
        "The given data was invalid.": "The given data was invalid.",
        "This password reset link will expire in {count} minutes.": "This password reset link will expire in {count} minutes.",
        "Title": "Title",
        "title": "title",
        "to": "to",
        "Toggle navigation": "Toggle navigation",
        "Too Many Requests": "Too Many Requests",
        "Type": "Type",
        "Type Request": "Type Request",
        "Unauthorized": "Unauthorized",
        "Unfortunately, the page you are looking for is not (no longer) available. We apologize for the inconvenience.": "Unfortunately, the page you are looking for is not (no longer) available. We apologize for the inconvenience.",
        "Upload C.V": "Upload C.V",
        "Upload C.V *": "Upload C.V *",
        "Uploaded image ad background for card": "Uploaded image ad background for card",
        "Uploaded image next to text": "Uploaded image next to text",
        "url": "url",
        " vacancies": "vacancies",
        "Vacancies": "vacancies",
        "vacancies": "vacancies",
        "vacancy": "vacancy",
        "Variants": "Variants",
        "Verify Email Address": "Verify Email Address",
        "we are looking for?": "we are looking for?",
        "Web Page": "Web Page",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.": "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.",
        "We have received your message. One of our employees will contact you as soon as possible.": "We have received your message. One of our employees will contact you as soon as possible.",
        "Whoops!": "Whoops!",
        "You are receiving this email because we received a password reset request for your account.": "You are receiving this email because we received a password reset request for your account.",
        "You can find the mobile car wash at various supermarkets in the Netherlands and Belgium. The mobile car wash can be found at Albert Heijn, Jumbo, Coop, Makro, Spar, Plus, Carrefour Market, Delhaize, Colruyt Retail Partners, Poiesz Supermarkets, DekaMarkt and Alvo, among others.": "You can find the mobile car wash at various supermarkets in the Netherlands and Belgium. The mobile car wash can be found at Albert Heijn, Jumbo, Coop, Makro, Spar, Plus, Carrefour Market, Delhaize, Colruyt Retail Partners, Poiesz Supermarkets, DekaMarkt and Alvo, among others.",
        "Your message has been sent successfully!": "Your message has been sent successfully!",
        "Youtube icon": "Youtube icon",
        "Youtube Video ID": "Youtube Video ID",
        "Youtube video ID": "Youtube video ID",
        "Zip*": "Zip*",
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "accepted_if": "The {attribute} must be accepted when {other} is {value}.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} must only contain letters.",
            "alpha_dash": "The {attribute} must only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} must only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "array": "The {attribute} must have between {min} and {max} items.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "numeric": "The {attribute} must be between {min} and {max}.",
                "string": "The {attribute} must be between {min} and {max} characters."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "current_password": "The password is incorrect.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "declined": "The {attribute} must be declined.",
            "declined_if": "The {attribute} must be declined when {other} is {value}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "doesnt_end_with": "The {attribute} may not end with one of the following: {values}.",
            "doesnt_start_with": "The {attribute} may not start with one of the following: {values}.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}.",
            "enum": "The selected {attribute} is invalid.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "array": "The {attribute} must have more than {value} items.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "numeric": "The {attribute} must be greater than {value}.",
                "string": "The {attribute} must be greater than {value} characters."
            },
            "gte": {
                "array": "The {attribute} must have {value} items or more.",
                "file": "The {attribute} must be greater than or equal to {value} kilobytes.",
                "numeric": "The {attribute} must be greater than or equal to {value}.",
                "string": "The {attribute} must be greater than or equal to {value} characters."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lowercase": "The {attribute} must be lowercase.",
            "lt": {
                "array": "The {attribute} must have less than {value} items.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "numeric": "The {attribute} must be less than {value}.",
                "string": "The {attribute} must be less than {value} characters."
            },
            "lte": {
                "array": "The {attribute} must not have more than {value} items.",
                "file": "The {attribute} must be less than or equal to {value} kilobytes.",
                "numeric": "The {attribute} must be less than or equal to {value}.",
                "string": "The {attribute} must be less than or equal to {value} characters."
            },
            "mac_address": "The {attribute} must be a valid MAC address.",
            "max": {
                "array": "The {attribute} must not have more than {max} items.",
                "file": "The {attribute} must not be greater than {max} kilobytes.",
                "numeric": "The {attribute} must not be greater than {max}.",
                "string": "The {attribute} must not be greater than {max} characters."
            },
            "max_digits": "The {attribute} must not have more than {max} digits.",
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "array": "The {attribute} must have at least {min} items.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "numeric": "The {attribute} must be at least {min}.",
                "string": "The {attribute} must be at least {min} characters."
            },
            "min_digits": "The {attribute} must have at least {min} digits.",
            "multiple_of": "The {attribute} must be a multiple of {value}.",
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "password": {
                "letters": "The {attribute} must contain at least one letter.",
                "mixed": "The {attribute} must contain at least one uppercase and one lowercase letter.",
                "numbers": "The {attribute} must contain at least one number.",
                "symbols": "The {attribute} must contain at least one symbol.",
                "uncompromised": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}."
            },
            "present": "The {attribute} field must be present.",
            "prohibited": "The {attribute} field is prohibited.",
            "prohibited_if": "The {attribute} field is prohibited when {other} is {value}.",
            "prohibited_unless": "The {attribute} field is prohibited unless {other} is in {values}.",
            "prohibits": "The {attribute} field prohibits {other} from being present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_array_keys": "The {attribute} field must contain entries for: {values}.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_if_accepted": "The {attribute} field is required when {other} is accepted.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "array": "The {attribute} must contain {size} items.",
                "file": "The {attribute} must be {size} kilobytes.",
                "numeric": "The {attribute} must be {size}.",
                "string": "The {attribute} must be {size} characters."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}.",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid timezone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "uppercase": "The {attribute} must be uppercase.",
            "url": "The {attribute} must be a valid URL.",
            "uuid": "The {attribute} must be a valid UUID.",
            "attributes": {
                "address": "address",
                "age": "age",
                "amount": "amount",
                "area": "area",
                "available": "available",
                "birthday": "birthday",
                "body": "body",
                "city": "city",
                "content": "content",
                "country": "country",
                "created_at": "created at",
                "creator": "creator",
                "current_password": "current password",
                "date": "date",
                "date_of_birth": "date of birth",
                "day": "day",
                "deleted_at": "deleted at",
                "description": "description",
                "district": "district",
                "duration": "duration",
                "email": "email",
                "excerpt": "excerpt",
                "filter": "filter",
                "first_name": "first name",
                "gender": "gender",
                "group": "group",
                "hour": "hour",
                "image": "image",
                "last_name": "last name",
                "lesson": "lesson",
                "line_address_1": "line address 1",
                "line_address_2": "line address 2",
                "message": "message",
                "middle_name": "middle name",
                "minute": "minute",
                "mobile": "mobile",
                "month": "month",
                "name": "name",
                "national_code": "national code",
                "number": "number",
                "password": "password",
                "password_confirmation": "password confirmation",
                "phone": "phone",
                "photo": "photo",
                "postal_code": "postal code",
                "price": "price",
                "province": "province",
                "recaptcha_response_field": "recaptcha response field",
                "remember": "remember",
                "restored_at": "restored at",
                "result_text_under_image": "result text under image",
                "role": "role",
                "second": "second",
                "sex": "sex",
                "short_text": "short text",
                "size": "size",
                "state": "state",
                "street": "street",
                "student": "student",
                "subject": "subject",
                "teacher": "teacher",
                "terms": "terms",
                "test_description": "test description",
                "test_locale": "test locale",
                "test_name": "test name",
                "text": "text",
                "time": "time",
                "title": "title",
                "updated_at": "updated at",
                "username": "username",
                "year": "year"
            }
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "password": "The password is incorrect.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "pagination": {
            "next": "Next &raquo;",
            "previous": "&laquo; Previous"
        },
        "passwords": {
            "reset": "Your password has been reset!",
            "sent": "We have emailed your password reset link!",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that email address."
        }
    },
    "es": {
        "(and {count} more error)": "(y {count} error más)",
        "(and {count} more errors)": "(y {count} errores más)",
        "*Required fields": "*Campos obligatorios",
        "404 - Page not found": "404 - Página no encontrada",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{count} characters": "{count} caracteres",
        "Active": "Activo",
        "Addition": "Adicional",
        "Address details": "Dirección",
        "All news items": "Todas las noticias",
        "All questions": "Todas las preguntas",
        "All rights reserved.": "Todos los derechos reservados.",
        "alt text": "texto alternativo",
        "Amount": "Importe",
        "answer": "responder",
        "Apply": "Solicitar",
        "Are you the": "¿Es usted el",
        "Are you the one we are looking for?": "¿Es usted a quien buscamos?",
        "Arrow": "Flecha",
        "Back to home": "Volver a la página de inicio",
        "Become a customer?": "¿Convertirse en cliente?",
        "Button label": "Etiqueta del botón",
        "Card title": "Título de la tarjeta",
        "Choose file": "Elegir archivo",
        "CleanShopping (Headquarters)": "CleanShopping (Headquarters)",
        "CleanShopping North": "CleanShopping Norte",
        "CleanShopping South (Headquarters)": "CleanShopping South (Sede central)",
        "CleanShopping ©": "CleanShopping ©",
        "Clients": "Clientes",
        "Company data": "Datos de la empresa",
        "Company name": "Nombre de la empresa",
        "Company name *": "Nombre de la empresa *",
        "Confirmation {source}": "Confirmación {fuente}",
        "Confirmation Request Offer": "Oferta de solicitud de confirmación",
        "Contact information": "Información de contacto",
        "Content": "Contenido",
        "Country": "País",
        "Country *": "País *",
        "Dear {name},": "Estimado {name}",
        "Description": "Descripción",
        "description": "descripción",
        "Discount": "Descuento",
        "Discount active": "Descuento activo",
        "Download leaflet": "Descargar el folleto",
        "Do you have a question or request? Feel free to contact us so that our team can help you. Please fill out the quote form below, so we can send you an appropriate price right away.": "¿Tiene alguna pregunta o petición? No dude en ponerse en contacto con nosotros para que nuestro equipo pueda ayudarle. Rellene el siguiente formulario de presupuesto para que podamos enviarle un precio adecuado de inmediato.",
        "E-mail": "Correo electrónico",
        "E-mail *": "Correo electrónico *",
        "End": "Fin",
        "Envelope icon": "Icono del sobre",
        "Excerpt": "Extracto",
        "excerpt": "extracto",
        "External URL": "URL externa",
        "Facebook icon": "Icono de Facebook",
        "Faq": "Faq",
        "Forbidden": "Prohibido",
        "Go to page {page}": "Ir a la página {page}",
        "Go to the Terms and Conditions page": "Ir a la página de Términos y Condiciones",
        "Go to the Terms and Privacy declaration page": "Ir a la página de Términos y Declaración de Privacidad",
        "Gum removal": "Eliminación de chicles",
        "Hello!": "¡Hola!",
        "Hits": "Hits",
        "hoeei": "hoeei",
        "Home": "Inicio",
        "House number": "Número de casa",
        "House number *": "Número de casa *",
        "Hygiene station": "Servicio de limpieza de marquesinas",
        "Hygiepole": "HygiePole (pedestal higiénico)",
        "Hyiepole": "Hyiepole",
        "I agree with the terms and conditions*": "Acepto las condiciones generales*.",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "Si se activa y no se carga ninguna imagen, se mostrará el banner de la página de inicio.",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "Si tiene problemas para hacer clic en el botón \"{actionText}\", copie y pegue la URL que aparece a continuación en su navegador:",
        "If you did not create an account, no further action is required.": "Si no ha creado una cuenta, no se requiere ninguna acción adicional.",
        "If you did not request a password reset, no further action is required.": "Si no ha solicitado el restablecimiento de contraseña, omita este mensaje de correo electrónico.",
        "Image": "Imagen",
        "Image {attribute}": "Images {attribute}",
        "Image for news card. Pageheader image can be edited on the page itself.": "Imagen para la tarjeta de noticias. La imagen del encabezado de página puede editarse en la propia página.",
        "Image for vacancy card": "Imagen para la tarjeta de vacante",
        "Image impression": "Impresión de imagen",
        "Image impression of cleanShopping": "Impresión de imagen de cleanShopping",
        "Image of hygiepole": "Imagen de hygiepole",
        "Image of repairs and maintance": "Imagen de reparaciones y mantenimiento",
        "Index Page": "Índice",
        "Instagram icon": "Icono de Instagram",
        "It is recommended to keep the {attribute} between {min} and {max} characters long": "Se recomienda mantener el atributo {entre} {min} y {max} caracteres de longitud",
        "Leave empty for no end date": "Dejar vacío si no hay fecha final",
        "Leave empty to use parent details": "Dejar vacío para utilizar los datos de los padres",
        "LinkedIn icon": "Icono de LinkedIn",
        "Login": "Iniciar sesión",
        "Logout": "Finalizar sesión",
        "Made by:": "Hecho por:",
        "Make a choice ...": "Haz una elección...",
        "Message": "Mensaje",
        "Message *": "Mensaje *",
        "minus icon": "icono menos",
        "Mobile car wash": "Servicio de lavado de carros y cestas ",
        "Motivation *": "Motivación *",
        "name": "nombre",
        "Name": "Nombre",
        "Name *": "Nombre *",
        "Netherlands": "Países Bajos",
        "New Quote Request": "Nueva solicitud de presupuesto",
        "News": "Noticias",
        " news items": "noticias",
        "news items": "noticias",
        "New URL": "Nueva URL",
        "Not Found": "No encontrado",
        "Number of cleanings per year": "Número de limpiezas al año",
        "Number of cleanings per year*": "Número de limpiezas al año",
        "Number of dollies": "Número de carros",
        "Number of dollies *": "Número de carros *",
        "Number of shopping baskets": "Número de cestas de la compra",
        "Number of shopping baskets *": "Número de cestas de la compra *",
        "Number of shopping carts": "Número de carros de la compra",
        "Number of shopping carts *": "Número de carros de la compra *",
        "Number of trolley parking(park box)": "Número de marquesinas de carritos (park box)",
        "Number of trolley parking(park box) *": "Número de marquesinas de carritos (park box) *",
        "Number of trolleys(baskets)": "Número de carros (cestas)",
        "Number of trolleys(baskets) *": "Número de carros(cestas) *",
        "of": "de",
        "Old URL": "URL antigua",
        "Oops, this page was not found": "Oops, no se ha encontrado esta página",
        "Other questions and/or comments": "Otras preguntas y/o comentarios",
        "Page (within website)": "Página (dentro del sitio web)",
        "Page Expired": "Página expirada",
        "Pages": "Páginas",
        "Pagination Navigation": "Navegación por los enlaces de paginación",
        "Parent category": "Categoría de padres",
        "Paste in here only the Youtube ID. That id the ID what you see in the url in the browser after https://www.youtube.com/watch?v=": "Pega aquí sólo el ID de Youtube. Que id el ID lo que se ve en la url en el navegador después de https://www.youtube.com/watch?v=",
        "Payment Required": "Pago requerido",
        "Perform maintenance and repairs": "Realizar tareas de mantenimiento y reparación",
        "Permanent": "Permanente",
        "Phone *": "Teléfono *",
        "Phone icon": "Icono del teléfono",
        "Place": "Lugar",
        "Place *": "Lugar",
        "Please click the button below to verify your email address.": "Por favor, haga clic en el botón de abajo para verificar su dirección de correo electrónico.",
        "plus icon": "icono plus",
        "Postal code": "Código Postal",
        "Postal code *": "Código postal *",
        "Privacy declaration": "Declaración de confidencialidad",
        "Published Date": "Fecha de publicación",
        "question": "pregunta",
        "Regards": "Saludos",
        "Register": "Registrarse",
        "Related articles": "Artículos relacionados",
        "Remove chewing gum": "Servicio de quitar chicles",
        "Repairs and maintenance": "Servicio de reparación y mantenimiento de carros y cestas ",
        "Request an offer": "Solicitar una oferta",
        "Request a quote direclty?": "¿Solicitar presupuesto directamente?",
        "Request quote": "Solicitar presupuesto",
        "Reset Password": "Restablecer contraseña",
        "Reset Password Notification": "Notificación de restablecimiento de contraseña",
        "results": "resultados",
        "SEO title": "Título SEO",
        "Server Error": "Error del servidor",
        "Service Unavailable": "Servicio no disponible",
        "Showing": "Mostrando",
        "Show Request quote and download leaflet buttons": "Mostrar Botones de solicitud de presupuesto y descarga de folletos",
        "Slug": "Babosa",
        "Socials": "Sociales",
        "Start": "Inicio",
        "Street name": "Nombre de la calle",
        "Street name *": "Nombre de la calle *",
        "Submit": "Enviar",
        "Terms and Conditions": "Condiciones generales",
        "Text": "Texto",
        "Thank you for your message!": "Gracias por su mensaje.",
        "Thank you for your Quote Request": "Gracias por su solicitud de presupuesto",
        "The {attribute} must contain at least one letter.": "La {attribute} debe contener al menos una letra.",
        "The {attribute} must contain at least one number.": "La {attribute} debe contener al menos un número.",
        "The {attribute} must contain at least one symbol.": "La {attribute} debe contener al menos un símbolo.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "La {attribute} debe contener al menos una letra mayúscula y una minúscula.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "La {attribute} proporcionada se ha visto comprometida en una filtración de datos (data leak). Elija una {attribute} diferente.",
        "The given data was invalid.": "Los datos proporcionados no son válidos.",
        "The request quote has been filled in. See all details below:": "Se ha rellenado la solicitud de presupuesto. Consulte todos los detalles a continuación:",
        "This password reset link will expire in {count} minutes.": "Este enlace de restablecimiento de contraseña expirará en {count} minutos.",
        "Title": "título",
        "title": "título",
        "to": "al",
        "Toggle navigation": "Alternar navegación",
        "Too Many Requests": "Demasiadas peticiones",
        "Type": "Tipo",
        "Type Request": "Tipo de solicitud",
        "Type request": "Tipo de solicitud",
        "Unauthorized": "No autorizado",
        "Unfortunately, the page you are looking for is not (no longer) available. We apologize for the inconvenience.": "Lamentablemente, la página que busca no está (ya no) disponible. Disculpe las molestias.",
        "Upload C.V": "Cargar C.V",
        "Upload C.V *": "Subir C.V *",
        "Uploaded image ad background for card": "Imagen subida como fondo de la tarjeta",
        "Uploaded image next to text": "Imagen subida junto al texto",
        "url": "url",
        " vacancies": "vacantes",
        "Vacancies": "vacantes",
        "vacancies": "vacantes",
        "vacancy": "vacante",
        "Variants": "Variantes",
        "Verify Email Address": "Confirme su correo electrónico",
        "we are looking for?": "¿estamos buscando?",
        "Web Page": "Página web",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.": "Hemos hecho todo lo posible, pero parece que la página que ha solicitado no existe (ya) o que puede haberse movido.",
        "We have received your message. One of our employees will contact you as soon as possible.": "Hemos recibido su mensaje. Uno de nuestros empleados se pondrá en contacto con usted lo antes posible.",
        "We have received your message and will contact you within 2 business days.": "Hemos recibido su mensaje y nos pondremos en contacto con usted en un plazo de 2 días laborables.",
        "Whoops!": "¡Ups!",
        "Yes": "Sí",
        "You are receiving this email because we received a password reset request for your account.": "Ha recibido este mensaje porque se solicitó un restablecimiento de contraseña para su cuenta.",
        "You can find the mobile car wash at various supermarkets in the Netherlands and Belgium. The mobile car wash can be found at Albert Heijn, Jumbo, Coop, Makro, Spar, Plus, Carrefour Market, Delhaize, Colruyt Retail Partners, Poiesz Supermarkets, DekaMarkt and Alvo, among others.": "Puede encontrar el túnel de lavado móvil en varios supermercados de los Países Bajos y Bélgica. Puede encontrar el túnel de lavado móvil en Albert Heijn, Jumbo, Coop, Makro, Spar, Plus, Carrefour Market, Delhaize, Colruyt Retail Partners, Poiesz Supermarkets, DekaMarkt y Alvo, entre otros.",
        "Your message has been sent successfully!": "Su mensaje se ha enviado correctamente.",
        "Youtube icon": "Icono de Youtube",
        "Youtube Video ID": "Youtube Video ID",
        "Youtube video ID": "ID del vídeo de Youtube",
        "Zip*": "Código Postal*",
        "validation": {
            "accepted": "{Attribute} debe ser aceptado.",
            "accepted_if": "{Attribute} debe ser aceptado cuando {other} sea {value}.",
            "active_url": "{Attribute} no es una URL válida.",
            "after": "{Attribute} debe ser una fecha posterior a {date}.",
            "after_or_equal": "{Attribute} debe ser una fecha posterior o igual a {date}.",
            "alpha": "{Attribute} sólo debe contener letras.",
            "alpha_dash": "{Attribute} sólo debe contener letras, números, guiones y guiones bajos.",
            "alpha_num": "{Attribute} sólo debe contener letras y números.",
            "array": "{Attribute} debe ser un conjunto.",
            "ascii": "El campo {attribute} solo debe contener caracteres alfanuméricos y símbolos de un solo byte.",
            "before": "{Attribute} debe ser una fecha anterior a {date}.",
            "before_or_equal": "{Attribute} debe ser una fecha anterior o igual a {date}.",
            "between": {
                "array": "{Attribute} tiene que tener entre {min} - {max} elementos.",
                "file": "{Attribute} debe pesar entre {min} - {max} kilobytes.",
                "numeric": "{Attribute} tiene que estar entre {min} - {max}.",
                "string": "{Attribute} tiene que tener entre {min} - {max} caracteres."
            },
            "boolean": "El campo {attribute} debe tener un valor verdadero o falso.",
            "confirmed": "La confirmación de {attribute} no coincide.",
            "current_password": "La contraseña es incorrecta.",
            "date": "{Attribute} no es una fecha válida.",
            "date_equals": "{Attribute} debe ser una fecha igual a {date}.",
            "date_format": "{Attribute} no corresponde al formato {format}.",
            "decimal": "El campo {attribute} debe tener {decimal} cifras decimales.",
            "declined": "{Attribute} debe ser rechazado.",
            "declined_if": "{Attribute} debe ser rechazado cuando {other} sea {value}.",
            "different": "{Attribute} y {other} deben ser diferentes.",
            "digits": "{Attribute} debe tener {digits} dígitos.",
            "digits_between": "{Attribute} debe tener entre {min} y {max} dígitos.",
            "dimensions": "Las dimensiones de la imagen {attribute} no son válidas.",
            "distinct": "El campo {attribute} contiene un valor duplicado.",
            "doesnt_end_with": "El campo {attribute} no puede finalizar con uno de los siguientes: {values}.",
            "doesnt_start_with": "El campo {attribute} no puede comenzar con uno de los siguientes: {values}.",
            "email": "{Attribute} no es un correo válido.",
            "ends_with": "El campo {attribute} debe finalizar con uno de los siguientes valores: {values}",
            "enum": "El {attribute} seleccionado es inválido.",
            "exists": "El {attribute} seleccionado es inválido.",
            "file": "El campo {attribute} debe ser un archivo.",
            "filled": "El campo {attribute} es obligatorio.",
            "gt": {
                "array": "El campo {attribute} debe tener más de {value} elementos.",
                "file": "El campo {attribute} debe tener más de {value} kilobytes.",
                "numeric": "El campo {attribute} debe ser mayor que {value}.",
                "string": "El campo {attribute} debe tener más de {value} caracteres."
            },
            "gte": {
                "array": "El campo {attribute} debe tener como mínimo {value} elementos.",
                "file": "El campo {attribute} debe tener como mínimo {value} kilobytes.",
                "numeric": "El campo {attribute} debe ser como mínimo {value}.",
                "string": "El campo {attribute} debe tener como mínimo {value} caracteres."
            },
            "image": "{Attribute} debe ser una imagen.",
            "in": "{Attribute} es inválido.",
            "in_array": "El campo {attribute} no existe en {other}.",
            "integer": "{Attribute} debe ser un número entero.",
            "ip": "{Attribute} debe ser una dirección IP válida.",
            "ipv4": "{Attribute} debe ser una dirección IPv4 válida.",
            "ipv6": "{Attribute} debe ser una dirección IPv6 válida.",
            "json": "El campo {attribute} debe ser una cadena JSON válida.",
            "lowercase": "El campo {attribute} debe estar en minúscula.",
            "lt": {
                "array": "El campo {attribute} debe tener menos de {value} elementos.",
                "file": "El campo {attribute} debe tener menos de {value} kilobytes.",
                "numeric": "El campo {attribute} debe ser menor que {value}.",
                "string": "El campo {attribute} debe tener menos de {value} caracteres."
            },
            "lte": {
                "array": "El campo {attribute} debe tener como máximo {value} elementos.",
                "file": "El campo {attribute} debe tener como máximo {value} kilobytes.",
                "numeric": "El campo {attribute} debe ser como máximo {value}.",
                "string": "El campo {attribute} debe tener como máximo {value} caracteres."
            },
            "mac_address": "El campo {attribute} debe ser una dirección MAC válida.",
            "max": {
                "array": "{Attribute} no debe tener más de {max} elementos.",
                "file": "{Attribute} no debe ser mayor que {max} kilobytes.",
                "numeric": "{Attribute} no debe ser mayor que {max}.",
                "string": "{Attribute} no debe ser mayor que {max} caracteres."
            },
            "max_digits": "El campo {attribute} no debe tener más de {max} dígitos.",
            "mimes": "{Attribute} debe ser un archivo con formato: {values}.",
            "mimetypes": "{Attribute} debe ser un archivo con formato: {values}.",
            "min": {
                "array": "{Attribute} debe tener al menos {min} elementos.",
                "file": "El tamaño de {attribute} debe ser de al menos {min} kilobytes.",
                "numeric": "El tamaño de {attribute} debe ser de al menos {min}.",
                "string": "{Attribute} debe contener al menos {min} caracteres."
            },
            "min_digits": "El campo {attribute} debe tener al menos {min} dígitos.",
            "missing": "El campo {attribute} no debe estar presente.",
            "missing_if": "El campo {attribute} no debe estar presente cuando {other} sea {value}.",
            "missing_unless": "El campo {attribute} no debe estar presente a menos que {other} sea {value}.",
            "missing_with": "El campo {attribute} no debe estar presente si alguno de los campos {values} está presente.",
            "missing_with_all": "El campo {attribute} no debe estar presente cuando los campos {values} estén presentes.",
            "multiple_of": "El campo {attribute} debe ser múltiplo de {value}",
            "not_in": "{Attribute} es inválido.",
            "not_regex": "El formato del campo {attribute} no es válido.",
            "numeric": "{Attribute} debe ser numérico.",
            "password": {
                "letters": "La {attribute} debe contener al menos una letra.",
                "mixed": "La {attribute} debe contener al menos una letra mayúscula y una minúscula.",
                "numbers": "La {attribute} debe contener al menos un número.",
                "symbols": "La {attribute} debe contener al menos un símbolo.",
                "uncompromised": "La {attribute} proporcionada se ha visto comprometida en una filtración de datos (data leak). Elija una {attribute} diferente."
            },
            "present": "El campo {attribute} debe estar presente.",
            "prohibited": "El campo {attribute} está prohibido.",
            "prohibited_if": "El campo {attribute} está prohibido cuando {other} es {value}.",
            "prohibited_unless": "El campo {attribute} está prohibido a menos que {other} sea {values}.",
            "prohibits": "El campo {attribute} prohibe que {other} esté presente.",
            "regex": "El formato de {attribute} es inválido.",
            "required": "El campo {attribute} es obligatorio.",
            "required_array_keys": "El campo {attribute} debe contener entradas para: {values}.",
            "required_if": "El campo {attribute} es obligatorio cuando {other} es {value}.",
            "required_if_accepted": "El campo {attribute} es obligatorio si {other} es aceptado.",
            "required_unless": "El campo {attribute} es obligatorio a menos que {other} esté en {values}.",
            "required_with": "El campo {attribute} es obligatorio cuando {values} está presente.",
            "required_with_all": "El campo {attribute} es obligatorio cuando {values} están presentes.",
            "required_without": "El campo {attribute} es obligatorio cuando {values} no está presente.",
            "required_without_all": "El campo {attribute} es obligatorio cuando ninguno de {values} está presente.",
            "same": "{Attribute} y {other} deben coincidir.",
            "size": {
                "array": "{Attribute} debe contener {size} elementos.",
                "file": "El tamaño de {attribute} debe ser {size} kilobytes.",
                "numeric": "El tamaño de {attribute} debe ser {size}.",
                "string": "{Attribute} debe contener {size} caracteres."
            },
            "starts_with": "El campo {attribute} debe comenzar con uno de los siguientes valores: {values}",
            "string": "El campo {attribute} debe ser una cadena de caracteres.",
            "timezone": "{Attribute} debe ser una zona horaria válida.",
            "ulid": "El campo {attribute} debe ser un ULID válido.",
            "unique": "El campo {attribute} ya ha sido registrado.",
            "uploaded": "Subir {attribute} ha fallado.",
            "uppercase": "El campo {attribute} debe estar en mayúscula.",
            "url": "{Attribute} debe ser una URL válida.",
            "uuid": "El campo {attribute} debe ser un UUID válido.",
            "attributes": {
                "address": "dirección",
                "age": "edad",
                "amount": "cantidad",
                "area": "área",
                "available": "disponible",
                "birthday": "cumpleaños",
                "body": "contenido",
                "city": "ciudad",
                "content": "contenido",
                "country": "país",
                "created_at": "creado el",
                "creator": "creador",
                "current_password": "contraseña actual",
                "date": "fecha",
                "date_of_birth": "fecha de nacimiento",
                "day": "día",
                "deleted_at": "eliminado el",
                "description": "descripción",
                "district": "distrito",
                "duration": "duración",
                "email": "correo electrónico",
                "excerpt": "extracto",
                "filter": "filtro",
                "first_name": "nombre",
                "gender": "género",
                "group": "grupo",
                "hour": "hora",
                "image": "imagen",
                "last_name": "apellido",
                "lesson": "lección",
                "line_address_1": "dirección de la línea 1",
                "line_address_2": "dirección de la línea 2",
                "message": "mensaje",
                "middle_name": "segundo nombre",
                "minute": "minuto",
                "mobile": "móvil",
                "month": "mes",
                "name": "nombre",
                "national_code": "código nacional",
                "number": "número",
                "password": "contraseña",
                "password_confirmation": "confirmación de la contraseña",
                "phone": "teléfono",
                "photo": "foto",
                "postal_code": "código postal",
                "price": "precio",
                "province": "provincia",
                "recaptcha_response_field": "respuesta del recaptcha",
                "remember": "recordar",
                "restored_at": "restaurado el",
                "result_text_under_image": "texto bajo la imagen",
                "role": "rol",
                "second": "segundo",
                "sex": "sexo",
                "short_text": "texto corto",
                "size": "tamaño",
                "state": "estado",
                "street": "calle",
                "student": "estudiante",
                "subject": "asunto",
                "teacher": "profesor",
                "terms": "términos",
                "test_description": "descripción de prueba",
                "test_locale": "prueba local",
                "test_name": "nombre de prueba",
                "text": "texto",
                "time": "hora",
                "title": "título",
                "updated_at": "actualizado el",
                "username": "usuario",
                "year": "año"
            }
        },
        "routes": {
            "news": "noticias",
            "vacancies": "vacantes"
        },
        "auth": {
            "failed": "Estas credenciales no coinciden con nuestros registros.",
            "password": "La contraseña es incorrecta.",
            "throttle": "Demasiados intentos de acceso. Por favor intente nuevamente en {seconds} segundos."
        },
        "pagination": {
            "next": "Siguiente &raquo;",
            "previous": "&laquo; Anterior"
        },
        "passwords": {
            "reset": "¡Su contraseña ha sido restablecida!",
            "sent": "¡Le hemos enviado por correo electrónico el enlace para restablecer su contraseña!",
            "throttled": "Por favor espere antes de intentar de nuevo.",
            "token": "El token de restablecimiento de contraseña es inválido.",
            "user": "No encontramos ningún usuario con ese correo electrónico."
        }
    },
    "fr": {
        "(and {count} more error)": "(et {count} erreur en plus)",
        "(and {count} more errors)": "(et {count} erreurs en plus)",
        "*Required fields": "*Champs obligatoires",
        "404 - Page not found": "404 - Page non trouvée",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{count} characters": "{count} les caractères",
        "Active": "Actif",
        "Addition": "Addition",
        "Address details": "Détails de l'adresse",
        "All news items": "Toutes les actualités",
        "All questions": "Toutes les questions",
        "All rights reserved.": "Tous droits réservés.",
        "alt text": "texte alternatif",
        "Amount": "Montant",
        "answer": "réponse",
        "Apply": "Appliquer",
        "Are you the": "Êtes-vous le",
        "Are you the one we are looking for?": "Êtes-vous celui que nous recherchons ?",
        "Arrow": "Flèche",
        "Back to home": "Retour à l'accueil",
        "Become a customer?": "Devenir un client ?",
        "Button label": "Étiquette de bouton",
        "Card title": "Titre de la carte",
        "Choose file": "Choisir le fichier",
        "CleanShopping (Headquarters)": "CleanShopping (Headquarters)",
        "CleanShopping North": "CleanShopping Nord",
        "CleanShopping South (Headquarters)": "CleanShopping South (Siège social)",
        "CleanShopping ©": "CleanShopping ©",
        "Clients": "Clients",
        "Company data": "Données de l'entreprise",
        "Company name *": "Nom de la société *",
        "Confirmation {source}": "Confirmation {source}",
        "Confirmation Request Offer": "Offre de demande de confirmation",
        "Contact information": "Informations de contact",
        "Content": "Contenu",
        "Country *": "Pays *",
        "Dear {name},": "Cher {name}",
        "Description": "Description",
        "description": "description",
        "Discount": "Remise",
        "Discount active": "Remise active",
        "Download leaflet": "Télécharger le dépliant",
        "Do you have a question or request? Feel free to contact us so that our team can help you. Please fill out the quote form below, so we can send you an appropriate price right away.": "Vous avez une question ou une demande ? N'hésitez pas à nous contacter pour que notre équipe puisse vous aider. Veuillez remplir le formulaire de devis ci-dessous, afin que nous puissions vous envoyer immédiatement un prix approprié.",
        "E-mail *": "Courriel *",
        "End": "Fin",
        "Envelope icon": "Icône d'enveloppe",
        "Excerpt": "Extrait",
        "excerpt": "extrait",
        "External URL": "URL externe",
        "Facebook icon": "Icône Facebook",
        "Faq": "Faq",
        "Forbidden": "Interdit",
        "Go to page {page}": "Aller à la page {page}",
        "Go to the Terms and Conditions page": "Aller à la page des termes et conditions",
        "Go to the Terms and Privacy declaration page": "Aller à la page de déclaration des termes et de la vie privée",
        "Gum removal": "Enlèvement de chewing-gum",
        "Hello!": "Bonjour !",
        "Hits": "Hits",
        "hoeei": "hoeei",
        "Home": "Accueil",
        "House number *": "Numéro de la maison *",
        "Hygiene station": "Poste d'hygiène",
        "Hygiepole": "Hygiepole",
        "Hyiepole": "Hyiepole",
        "I agree with the terms and conditions*": "Je suis d'accord avec les termes et conditions*.",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "Si cette option est activée et qu'aucune image n'est téléchargée, la bannière de la page d'accueil sera affichée.",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "Si vous avez des difficultés à cliquer sur le bouton \"{actionText}\", copiez et collez l'URL ci-dessous dans votre navigateur web :",
        "If you did not create an account, no further action is required.": "Si vous n'avez pas créé de compte, vous pouvez ignorer ce message.",
        "If you did not request a password reset, no further action is required.": "Si vous n'avez pas demandé de réinitialisation de mot de passe, vous pouvez ignorer ce message.",
        "Image": "Image",
        "Image {attribute}": "Image {attribute}",
        "Image for news card. Pageheader image can be edited on the page itself.": "Image pour la carte de nouvelles. L'image de l'en-tête de page peut être modifiée sur la page elle-même.",
        "Image for vacancy card": "Image pour la carte de vacance d'emploi",
        "Image impression": "Impression d'image",
        "Image impression of cleanShopping": "Impression d'image de cleanShopping",
        "Image of hygiepole": "Image de l'hygiepole",
        "Image of repairs and maintance": "Image des réparations et de l'entretien",
        "Index Page": "Page d'index",
        "Instagram icon": "icône Instagram",
        "It is recommended to keep the {attribute} between {min} and {max} characters long": "Il est recommandé de maintenir la longueur de l'attribut {attribute} {min} et {max} caractères.",
        "Leave empty for no end date": "Laissez vide si vous n'avez pas de date de fin",
        "Leave empty to use parent details": "Laissez vide pour utiliser les détails du parent",
        "LinkedIn icon": "Icône LinkedIn",
        "Login": "Connexion",
        "Logout": "Déconnexion",
        "Made by:": "Réalisé par :",
        "Make a choice ...": "Faites un choix...",
        "Message *": "Message *",
        "minus icon": "icône moins",
        "Mobile car wash": "Lavage mobile de voitures",
        "Motivation *": "Motivation *",
        "name": "nom",
        "Name *": "Nom *",
        "Netherlands": "Pays-Bas",
        "News": "Nouvelles",
        " news items": "articles d'actualité",
        "news items": "articles d'actualité",
        "New URL": "Nouvelle URL",
        "Not Found": "Non trouvé",
        "Number of cleanings per year": "Nombre de nettoyages par an",
        "Number of cleanings per year*": "Nombre de nettoyages par an*",
        "Number of dollies": "Nombre de chariots",
        "Number of dollies *": "Nombre de chariots *",
        "Number of shopping baskets": "Nombre de paniers à provisions",
        "Number of shopping baskets *": "Nombre de paniers à provisions *",
        "Number of shopping carts": "Nombre de paniers d'achat",
        "Number of shopping carts *": "Nombre de paniers d'achat *",
        "Number of trolley parking(park box)": "Nombre de parkings à chariots (park box)",
        "Number of trolley parking(park box) *": "Nombre de parkings à trolley (park box) *",
        "Number of trolleys(baskets)": "Nombre de chariots (paniers)",
        "Number of trolleys(baskets) *": "Nombre de chariots (paniers) *",
        "of": "de",
        "Old URL": "Ancienne URL",
        "Oops, this page was not found": "Oups, cette page n'a pas été trouvée",
        "Other questions and/or comments": "Autres questions et/ou commentaires",
        "Page (within website)": "Page (dans le site web)",
        "Page Expired": "Page expirée",
        "Pages": "Pages",
        "Pagination Navigation": "Pagination",
        "Parent category": "Catégorie de parents",
        "Paste in here only the Youtube ID. That id the ID what you see in the url in the browser after https://www.youtube.com/watch?v=": "Collez ici uniquement l'ID de Youtube. C'est l'ID que vous voyez dans l'url dans le navigateur après https://www.youtube.com/watch?v=.",
        "Payment Required": "Payment Required",
        "Perform maintenance and repairs": "Effectuer l'entretien et les réparations",
        "Permanent": "Permanent",
        "Phone *": "Téléphone *",
        "Phone icon": "Icône du téléphone",
        "Place": "Lieu",
        "Place *": "Place *",
        "Please click the button below to verify your email address.": "Veuillez cliquer sur le bouton ci-dessous pour vérifier votre adresse e-mail :",
        "plus icon": "icône plus",
        "Postal code *": "Code postal *",
        "Privacy declaration": "Déclaration de confidentialité",
        "Published Date": "Date de publication",
        "question": "question",
        "Regards": "Cordialement",
        "Register": "Inscription",
        "Related articles": "Articles connexes",
        "Remove chewing gum": "Retirer le chewing-gum",
        "Repairs and maintenance": "Réparations et entretien",
        "Request an offer": "Demander une offre",
        "Request a quote direclty?": "Demander un devis directement ?",
        "Request quote": "Demande de devis",
        "Reset Password": "Réinitialisation du mot de passe",
        "Reset Password Notification": "Notification de réinitialisation du mot de passe",
        "results": "résultats",
        "SEO title": "Titre de référencement",
        "Server Error": "Erreur serveur",
        "Service Unavailable": "Service indisponible",
        "Showing": "Montrant",
        "Show Request quote and download leaflet buttons": "Afficher les boutons Demande de devis et téléchargement de la brochure",
        "Slug": "Limace",
        "Socials": "Sociales",
        "Start": "Début",
        "Street name *": "Nom de la rue *",
        "Submit": "Soumettre",
        "Terms and Conditions": "Modalités et conditions",
        "Text": "Texte",
        "Thank you for your message!": "Merci pour votre message !",
        "Thank you for your Quote Request": "Merci pour votre demande de devis",
        "The {attribute} must contain at least one letter.": "Le champ {attribute} doit avoir au moins une lettre.",
        "The {attribute} must contain at least one number.": "Le champ {attribute} doit avoir au moins un numéro.",
        "The {attribute} must contain at least one symbol.": "Le champ {attribute} doit avoir au moins un symbole.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "Le champ {attribute} doit avoir au moins une lettre majuscule et une lettre minuscule.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "La valeur du champ {attribute} est apparue dans une fuite de données. Veuillez choisir une valeur différente.",
        "The given data was invalid.": "La donnée renseignée est invalide.",
        "This password reset link will expire in {count} minutes.": "Ce lien de réinitialisation du mot de passe expirera dans {count} minutes.",
        "Title": "titre",
        "title": "titre",
        "to": "à",
        "Toggle navigation": "Basculer la navigation",
        "Too Many Requests": "Trop de requêtes",
        "Type": "Type",
        "Type Request": "Demande de type",
        "Unauthorized": "Non autorisé",
        "Unfortunately, the page you are looking for is not (no longer) available. We apologize for the inconvenience.": "Malheureusement, la page que vous recherchez n'est pas (plus) disponible. Nous vous prions de nous excuser pour ce désagrément.",
        "Upload C.V": "Télécharger le C.V.",
        "Upload C.V *": "Télécharger le C.V. *",
        "Uploaded image ad background for card": "Image téléchargée comme fond d'écran pour la carte",
        "Uploaded image next to text": "Image téléchargée à côté du texte",
        "url": "url",
        " vacancies": "postes vacants",
        "Vacancies": "postes vacants",
        "vacancies": "postes vacants",
        "vacancy": "poste vacant",
        "Variants": "Variantes",
        "Verify Email Address": "Vérifier l'adresse e-mail",
        "we are looking for?": "que nous recherchons ?",
        "Web Page": "Page web",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.": "Nous avons fait de notre mieux, mais il semble que la page que vous avez demandée n'existe pas (plus) ou qu'elle ait été déplacée.",
        "We have received your message. One of our employees will contact you as soon as possible.": "Nous avons reçu votre message. Un de nos employés vous contactera dans les plus brefs délais.",
        "We have received your message and will contact you within 2 business days.": "Nous avons reçu votre message et nous vous contacterons dans les deux jours ouvrables.",
        "Whoops!": "Oups !",
        "You are receiving this email because we received a password reset request for your account.": "Vous recevez cet e-mail car nous avons reçu une demande de réinitialisation de mot de passe pour votre compte.",
        "You can find the mobile car wash at various supermarkets in the Netherlands and Belgium. The mobile car wash can be found at Albert Heijn, Jumbo, Coop, Makro, Spar, Plus, Carrefour Market, Delhaize, Colruyt Retail Partners, Poiesz Supermarkets, DekaMarkt and Alvo, among others.": "Vous pouvez trouver le lave-auto mobile dans différents supermarchés aux Pays-Bas et en Belgique. Le lave-auto mobile se trouve entre autres chez Albert Heijn, Jumbo, Coop, Makro, Spar, Plus, Carrefour Market, Delhaize, Colruyt Retail Partners, Poiesz Supermarkets, DekaMarkt et Alvo.",
        "Your message has been sent successfully!": "Votre message a été envoyé avec succès !",
        "Youtube icon": "Icône Youtube",
        "Youtube Video ID": "ID de la vidéo Youtube",
        "Youtube video ID": "ID de la vidéo Youtube",
        "Zip*": "Zip*",
        "validation": {
            "accepted": "Le champ {attribute} doit être accepté.",
            "accepted_if": "Le champ {attribute} doit être accepté quand {other} a la valeur {value}.",
            "active_url": "Le champ {attribute} n'est pas une URL valide.",
            "after": "Le champ {attribute} doit être une date postérieure au {date}.",
            "after_or_equal": "Le champ {attribute} doit être une date postérieure ou égale au {date}.",
            "alpha": "Le champ {attribute} doit contenir uniquement des lettres.",
            "alpha_dash": "Le champ {attribute} doit contenir uniquement des lettres, des chiffres et des tirets.",
            "alpha_num": "Le champ {attribute} doit contenir uniquement des chiffres et des lettres.",
            "array": "Le champ {attribute} doit être un tableau.",
            "ascii": "Le champ {attribute} ne doit contenir que des caractères alphanumériques et des symboles codés sur un octet.",
            "before": "Le champ {attribute} doit être une date antérieure au {date}.",
            "before_or_equal": "Le champ {attribute} doit être une date antérieure ou égale au {date}.",
            "between": {
                "array": "Le tableau {attribute} doit contenir entre {min} et {max} éléments.",
                "file": "La taille du fichier de {attribute} doit être comprise entre {min} et {max} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être comprise entre {min} et {max}.",
                "string": "Le texte {attribute} doit contenir entre {min} et {max} caractères."
            },
            "boolean": "Le champ {attribute} doit être vrai ou faux.",
            "confirmed": "Le champ de confirmation {attribute} ne correspond pas.",
            "current_password": "Le mot de passe est incorrect.",
            "date": "Le champ {attribute} n'est pas une date valide.",
            "date_equals": "Le champ {attribute} doit être une date égale à {date}.",
            "date_format": "Le champ {attribute} ne correspond pas au format {format}.",
            "decimal": "Le champ {attribute} doit comporter {decimal} décimales.",
            "declined": "Le champ {attribute} doit être décliné.",
            "declined_if": "Le champ {attribute} doit être décliné quand {other} a la valeur {value}.",
            "different": "Les champs {attribute} et {other} doivent être différents.",
            "digits": "Le champ {attribute} doit contenir {digits} chiffres.",
            "digits_between": "Le champ {attribute} doit contenir entre {min} et {max} chiffres.",
            "dimensions": "La taille de l'image {attribute} n'est pas conforme.",
            "distinct": "Le champ {attribute} a une valeur en double.",
            "doesnt_end_with": "Le champ {attribute} ne doit pas finir avec une des valeurs suivantes : {values}.",
            "doesnt_start_with": "Le champ {attribute} ne doit pas commencer avec une des valeurs suivantes : {values}.",
            "email": "Le champ {attribute} doit être une adresse e-mail valide.",
            "ends_with": "Le champ {attribute} doit se terminer par une des valeurs suivantes : {values}",
            "enum": "Le champ {attribute} sélectionné est invalide.",
            "exists": "Le champ {attribute} sélectionné est invalide.",
            "file": "Le champ {attribute} doit être un fichier.",
            "filled": "Le champ {attribute} doit avoir une valeur.",
            "gt": {
                "array": "Le tableau {attribute} doit contenir plus de {value} éléments.",
                "file": "La taille du fichier de {attribute} doit être supérieure à {value} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être supérieure à {value}.",
                "string": "Le texte {attribute} doit contenir plus de {value} caractères."
            },
            "gte": {
                "array": "Le tableau {attribute} doit contenir au moins {value} éléments.",
                "file": "La taille du fichier de {attribute} doit être supérieure ou égale à {value} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être supérieure ou égale à {value}.",
                "string": "Le texte {attribute} doit contenir au moins {value} caractères."
            },
            "image": "Le champ {attribute} doit être une image.",
            "in": "Le champ {attribute} est invalide.",
            "in_array": "Le champ {attribute} n'existe pas dans {other}.",
            "integer": "Le champ {attribute} doit être un entier.",
            "ip": "Le champ {attribute} doit être une adresse IP valide.",
            "ipv4": "Le champ {attribute} doit être une adresse IPv4 valide.",
            "ipv6": "Le champ {attribute} doit être une adresse IPv6 valide.",
            "json": "Le champ {attribute} doit être un document JSON valide.",
            "lowercase": "Le champ {attribute} doit être en minuscules.",
            "lt": {
                "array": "Le tableau {attribute} doit contenir moins de {value} éléments.",
                "file": "La taille du fichier de {attribute} doit être inférieure à {value} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être inférieure à {value}.",
                "string": "Le texte {attribute} doit contenir moins de {value} caractères."
            },
            "lte": {
                "array": "Le tableau {attribute} doit contenir au plus {value} éléments.",
                "file": "La taille du fichier de {attribute} doit être inférieure ou égale à {value} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être inférieure ou égale à {value}.",
                "string": "Le texte {attribute} doit contenir au plus {value} caractères."
            },
            "mac_address": "Le champ {attribute} doit être une adresse MAC valide.",
            "max": {
                "array": "Le tableau {attribute} ne peut pas contenir plus que {max} éléments.",
                "file": "La taille du fichier de {attribute} ne peut pas dépasser {max} kilo-octets.",
                "numeric": "La valeur de {attribute} ne peut pas être supérieure à {max}.",
                "string": "Le texte de {attribute} ne peut pas contenir plus de {max} caractères."
            },
            "max_digits": "Le champ {attribute} ne doit pas avoir plus de {max} chiffres.",
            "mimes": "Le champ {attribute} doit être un fichier de type : {values}.",
            "mimetypes": "Le champ {attribute} doit être un fichier de type : {values}.",
            "min": {
                "array": "Le tableau {attribute} doit contenir au moins {min} éléments.",
                "file": "La taille du fichier de {attribute} doit être supérieure ou égale à {min} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être supérieure ou égale à {min}.",
                "string": "Le texte de {attribute} doit contenir au moins {min} caractères."
            },
            "min_digits": "Le champ {attribute} doit avoir au moins {min} chiffres.",
            "missing": "The {attribute} field must be missing.",
            "missing_if": "The {attribute} field must be missing when {other} is {value}.",
            "missing_unless": "The {attribute} field must be missing unless {other} is {value}.",
            "missing_with": "The {attribute} field must be missing when {values} is present.",
            "missing_with_all": "The {attribute} field must be missing when {values} are present.",
            "multiple_of": "La valeur de {attribute} doit être un multiple de {value}",
            "not_in": "Le champ {attribute} sélectionné n'est pas valide.",
            "not_regex": "Le format du champ {attribute} n'est pas valide.",
            "numeric": "Le champ {attribute} doit contenir un nombre.",
            "password": {
                "letters": "Le champ {attribute} doit contenir au moins une lettre.",
                "mixed": "Le champ {attribute} doit contenir au moins une majuscule et une minuscule.",
                "numbers": "Le champ {attribute} doit contenir au moins un chiffre.",
                "symbols": "Le champ {attribute} doit contenir au moins un symbole.",
                "uncompromised": "La valeur du champ {attribute} est apparue dans une fuite de données. Veuillez choisir une valeur différente."
            },
            "present": "Le champ {attribute} doit être présent.",
            "prohibited": "Le champ {attribute} est interdit.",
            "prohibited_if": "Le champ {attribute} est interdit quand {other} a la valeur {value}.",
            "prohibited_unless": "Le champ {attribute} est interdit à moins que {other} est l'une des valeurs {values}.",
            "prohibits": "Le champ {attribute} interdit {other} d'être présent.",
            "regex": "Le format du champ {attribute} est invalide.",
            "required": "Le champ {attribute} est obligatoire.",
            "required_array_keys": "Le champ {attribute} doit contenir des entrées pour : {values}.",
            "required_if": "Le champ {attribute} est obligatoire quand la valeur de {other} est {value}.",
            "required_if_accepted": "Le champ {attribute} est obligatoire quand le champ {other} a été accepté.",
            "required_unless": "Le champ {attribute} est obligatoire sauf si {other} est {values}.",
            "required_with": "Le champ {attribute} est obligatoire quand {values} est présent.",
            "required_with_all": "Le champ {attribute} est obligatoire quand {values} sont présents.",
            "required_without": "Le champ {attribute} est obligatoire quand {values} n'est pas présent.",
            "required_without_all": "Le champ {attribute} est requis quand aucun de {values} n'est présent.",
            "same": "Les champs {attribute} et {other} doivent être identiques.",
            "size": {
                "array": "Le tableau {attribute} doit contenir {size} éléments.",
                "file": "La taille du fichier de {attribute} doit être de {size} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être {size}.",
                "string": "Le texte de {attribute} doit contenir {size} caractères."
            },
            "starts_with": "Le champ {attribute} doit commencer avec une des valeurs suivantes : {values}",
            "string": "Le champ {attribute} doit être une chaîne de caractères.",
            "timezone": "Le champ {attribute} doit être un fuseau horaire valide.",
            "ulid": "Le champ {attribute} doit être un ULID valide.",
            "unique": "La valeur du champ {attribute} est déjà utilisée.",
            "uploaded": "Le fichier du champ {attribute} n'a pu être téléversé.",
            "uppercase": "Le champ {attribute} doit être en majuscules.",
            "url": "Le format de l'URL de {attribute} n'est pas valide.",
            "uuid": "Le champ {attribute} doit être un UUID valide",
            "attributes": {
                "address": "adresse",
                "age": "âge",
                "amount": "montant",
                "area": "zone",
                "available": "disponible",
                "birthday": "anniversaire",
                "body": "corps",
                "city": "ville",
                "content": "contenu",
                "country": "pays",
                "created_at": "créé à",
                "creator": "créateur",
                "current_password": "mot de passe actuel",
                "date": "Date",
                "date_of_birth": "date de naissance",
                "day": "jour",
                "deleted_at": "supprimé à",
                "description": "la description",
                "district": "quartier",
                "duration": "durée",
                "email": "adresse e-mail",
                "excerpt": "extrait",
                "filter": "filtre",
                "first_name": "prénom",
                "gender": "genre",
                "group": "groupe",
                "hour": "heure",
                "image": "image",
                "last_name": "nom",
                "lesson": "leçon",
                "line_address_1": "ligne d'adresse 1",
                "line_address_2": "ligne d'adresse 2",
                "message": "message",
                "middle_name": "deuxième prénom",
                "minute": "minute",
                "mobile": "portable",
                "month": "mois",
                "name": "nom",
                "national_code": "code national",
                "number": "numéro",
                "password": "mot de passe",
                "password_confirmation": "confirmation du mot de passe",
                "phone": "téléphone",
                "photo": "photo",
                "postal_code": "code postal",
                "price": "prix",
                "province": "région",
                "recaptcha_response_field": "champ de réponse recaptcha",
                "remember": "se souvenir",
                "restored_at": "restauré à",
                "result_text_under_image": "texte de résultat sous l'image",
                "role": "rôle",
                "second": "seconde",
                "sex": "sexe",
                "short_text": "texte court",
                "size": "taille",
                "state": "état",
                "street": "rue",
                "student": "étudiant",
                "subject": "sujet",
                "teacher": "professeur",
                "terms": "conditions",
                "test_description": "description test",
                "test_locale": "localisation test",
                "test_name": "nom test",
                "text": "texte",
                "time": "heure",
                "title": "titre",
                "updated_at": "mis à jour à",
                "username": "nom d'utilisateur",
                "year": "année"
            }
        },
        "routes": {
            "news": "nouvelles",
            "vacancies": "vacuités"
        },
        "auth": {
            "failed": "Ces identifiants ne correspondent pas à nos enregistrements.",
            "password": "Le mot de passe est incorrect",
            "throttle": "Tentatives de connexion trop nombreuses. Veuillez essayer de nouveau dans {seconds} secondes."
        },
        "pagination": {
            "next": "Suivant &raquo;",
            "previous": "&laquo; Précédent"
        },
        "passwords": {
            "reset": "Votre mot de passe a été réinitialisé !",
            "sent": "Nous vous avons envoyé par email le lien de réinitialisation du mot de passe !",
            "throttled": "Veuillez patienter avant de réessayer.",
            "token": "Ce jeton de réinitialisation du mot de passe n'est pas valide.",
            "user": "Aucun utilisateur n'a été trouvé avec cette adresse email."
        }
    },
    "nl": {
        "(and {count} more error)": "(and {count} more error)",
        "(and {count} more errors)": "(and {count} more errors)",
        "*Required fields": "*Verplichte velden",
        "404 - Page not found": "404 - Pagina niet gevonden",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{count} characters": "{count} characters",
        "About the cookies on this website": "Over de cookies op deze website",
        "Active": "Actief",
        "Addition": "Toevoeging",
        "Address details": "Adresgegevens",
        "All news items": "Alle nieuwsberichten",
        "All questions": "Alle vragen",
        "All rights reserved.": "Alle rechten voorbehouden.",
        "alt text": "alt-tekst",
        "Amount": "Aantallen",
        "answer": "antwoord",
        "Apply": "Solliciteren",
        "Are you the": "Ben jij de",
        "Are you the ${pageTitle} we are looking for?": "hoi",
        "Are you the one we are looking for?": "Ben jij degene waar wij naar zoeken?",
        "Arrow": "Pijl",
        "Back to home": "Terug naar home",
        "Become a customer?": "Klant worden?",
        "Button label": "Knop label",
        "Card title": "Titel van de kaart",
        "Choose file": "Kies bestand",
        "CleanShopping (Headquarters)": "CleanShopping (Headquarters)",
        "CleanShopping North": "CleanShopping Noord",
        "CleanShopping South (Headquarters)": "CleanShopping Zuid (Hoofdkantoor)",
        "CleanShopping ©": "CleanShopping ©",
        "Clients": "Klanten",
        "Company data": "Bedrijfsgegevens",
        "Company name": "Bedrijfsnaam",
        "Company name *": "Bedrijfsnaam *",
        "company_name:": "Bedrijfsnaam:",
        "Confirmation {source}": "Bevestiging {source}",
        "Confirmation Request Offer": "Nieuwe Offerteaanvraag",
        "Contact information": "Contactgegevens",
        "Content": "Inhoud",
        "Country": "Land",
        "Country *": "Land *",
        "Dear": "Beste",
        "Dear {name},": "Beste {name}",
        "Description": "Beschrijving",
        "description": "beschrijving",
        "Discount": "Korting",
        "Discount active": "Korting actief",
        "Download leaflet": "Download brochure",
        "Do you have a question or request? Feel free to contact us so that our team can help you. Please fill out the quote form below, so we can send you an appropriate price right away.": "Heeft u een vraag of verzoek? Neem gerust contact met ons op, zodat ons team u kan helpen. Vul het onderstaande offerteformulier in, zodat wij u direct een passende prijs kunnen sturen.",
        "E-mail *": "E-mail *",
        "End": "Einde",
        "Envelope icon": "Enveloppe pictogram",
        "Excerpt": "Uittreksel",
        "excerpt": "uittreksel",
        "External URL": "Externe URL",
        "Facebook icon": "Facebook-pictogram",
        "Faq": "Faq",
        "Forbidden": "Geen toegang",
        "Go to page {page}": "Ga naar pagina {page}",
        "Go to the Terms and Conditions page": "Ga naar de pagina Algemene Voorwaarden",
        "Go to the Terms and Privacy declaration page": "Ga naar de pagina Algemene Voorwaarden en Privacyverklaring",
        "Gum removal": "Kauwgom verwijderen",
        "Hello!": "Hallo!",
        "Hits": "Hits",
        "hoeei": "hoeei",
        "Home": "Home",
        "House number": "Huisnummer",
        "House number *": "Huisnummer *",
        "Hygiene station": "Hygiene station",
        "Hygiepole": "Hygiepole",
        "Hyiepole": "Hyiepole",
        "I agree with the terms and conditions*": "Ik ga akkoord met de voorwaarden*",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "Indien ingeschakeld, en er is geen afbeelding geupload, dan wordt de banner van de homepage getoond.",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "Als je problemen hebt met de \"{actionText}\" knop, kopieer en plak de URL hierondernin je webbrowser:",
        "If you did not create an account, no further action is required.": "Als je geen account hebt aangemaakt hoef je verder niets te doen.",
        "If you did not request a password reset, no further action is required.": "Als je geen wachtwoordherstel hebt aangevraagd, hoef je verder niets te doen.",
        "Image": "Afbeelding",
        "Image {attribute}": "Afbeelding {attribute}",
        "Image for news card. Pageheader image can be edited on the page itself.": "Afbeelding voor nieuwskaart. Pageheader afbeelding kan worden bewerkt op de pagina zelf.",
        "Image for vacancy card": "Afbeelding voor vacaturekaart",
        "Image impression": "Beeldimpressie",
        "Image impression of cleanShopping": "Beeldimpressie van cleanShopping",
        "Image of hygiepole": "Beeld van hygiepole",
        "Image of repairs and maintance": "Beeld van reparaties en onderhoud",
        "Index Page": "Indexpagina",
        "Instagram icon": "Instagram-pictogram",
        "It is recommended to keep the {attribute} between {min} and {max} characters long": "Het wordt aanbevolen het {attribuut} tussen {min} en {max} tekens lang te houden.",
        "Leave empty for no end date": "Leeg laten voor geen einddatum",
        "Leave empty to use parent details": "Leeg laten om hoofdgegevens te gebruiken",
        "LinkedIn icon": "LinkedIn-pictogram",
        "Login": "Inloggen",
        "Logout": "Uitloggen",
        "Made by:": "Gerealiseerd door:",
        "Make a choice ...": "Maak een keuze...",
        "Message": "Bericht",
        "Message *": "Bericht *",
        "message:": "Bericht",
        "minus icon": "min-pictogram",
        "Mobile car wash": "Mobiele wasstraat",
        "More information": "Meer informatie",
        "Motivation *": "Motivatie *",
        "Motivation:": "Motivatie:",
        "name": "naam",
        "Name *": "Naam *",
        "Name:": "Naam:",
        "Netherlands": "Nederland",
        "New application from": "Nieuwe sollicitatie van",
        "New Quote Request": "Nieuwe Offerteaanvraag",
        "News": "Nieuws",
        " news items": "nieuwsberichten",
        "news items": "nieuwsberichten",
        "New URL": "Nieuwe URL",
        "Not Found": "Niet gevonden",
        "Number of cleanings per year": "Aantal reinigingsbeurten per jaar",
        "Number of cleanings per year*": "Aantal reinigingsbeurten per jaar *",
        "Number of dollies": "Aantal dollies",
        "Number of dollies *": "Aantal dollies *",
        "Number of shopping baskets": "Aantal winkelmandjes",
        "Number of shopping baskets *": "Aantal winkelmandjes *",
        "Number of shopping carts": "Aantal winkelwagens",
        "Number of shopping carts *": "Aantal winkelwagens *",
        "Number of trolley parking(park box)": "Aantal wagenstallingen (parkbox)",
        "Number of trolley parking(park box) *": "Aantal wagenstallingen (parkbox) *",
        "Number of trolleys(baskets)": "Aantal trolleys (manden)",
        "Number of trolleys(baskets) *": "Aantal trolleys (manden) *",
        "of": "van",
        "Old URL": "Oude URL",
        "Oops, this page was not found": "Oeps, deze pagina is niet gevonden",
        "Other questions and/or comments": "Andere vragen en/of opmerkingen",
        "Page (within website)": "Pagina (binnen website)",
        "Page Expired": "Pagina niet meer geldig",
        "Pages": "Pagina's",
        "Pagination Navigation": "Paginanavigatie",
        "Parent category": "Hoofdcategorie",
        "Paste in here only the Youtube ID. That id the ID what you see in the url in the browser after https://www.youtube.com/watch?v=": "Plak hier alleen de Youtube ID in. Dat id het ID wat je ziet in de url in de browser na https://www.youtube.com/watch?v=",
        "Perform maintenance and repairs": "Onderhoud en reparaties uitvoeren",
        "Permanent": "Permanent",
        "Phone *": "Telefoon *",
        "Phone:": "Telefoon:",
        "Phone icon": "Telefoon icoon",
        "Place": "Plaats",
        "Place *": "Plaats *",
        "Please click the button below to verify your email address.": "Klik op de knop hieronder om je e-mailadres te verifiëren.",
        "plus icon": "plus-pictogram",
        "Postal code": "Postcode",
        "Postal code *": "Postcode *",
        "Privacy declaration": "Privacyverklaring",
        "privacy statement": "privacyverklaring",
        "Published Date": "Gepubliceerde datum",
        "question": "vraag",
        "Regards": "Met vriendelijke groet",
        "Register": "Registreren",
        "Related articles": "Gerelateerde berichten",
        "Remove chewing gum": "Kauwgom verwijderen",
        "Repairs and maintenance": "Reparaties en onderhoud",
        "Request an offer": "Offerte aanvragen",
        "Request a quote direclty?": "Direct een offerte aanvragen?",
        "Request quote": "Offerte aanvragen",
        "Reset Password": "Wachtwoord herstellen",
        "Reset Password Notification": "Wachtwoordherstel notificatie",
        "results": "resultaten",
        "SEO title": "SEO titel",
        "Server Error": "Server fout",
        "Service Unavailable": "Website onbeschikbaar",
        "Showing": "Toont",
        "Show Request quote and download leaflet buttons": "Toon Offerte aanvraag en download brochure knoppen",
        "Slug": "Permalink",
        "Socials": "Socials",
        "Start": "Start",
        "Street name": "Straatnaam",
        "Street name *": "Straatnaam *",
        "Submit": "Versturen",
        "Terms and Conditions": "Algemene Voorwaarden",
        "Text": "Tekst",
        "Thank you for your application. We will contact you shortly.": "Bedankt voor uw sollicitatie. Wij zullen binnenkort contact met u opnemen.",
        "Thank you for your message!": "Bedankt voor uw bericht!",
        "Thank you for your Quote Request": "Bedankt voor uw offerteaanvraag",
        "The {attribute} must contain at least one letter.": "Het {attribute} moet minimaal één letter bevatten.",
        "The {attribute} must contain at least one number.": "Het {attribute} moet minimaal één cijfer bevatten.",
        "The {attribute} must contain at least one symbol.": "Het {attribute} moet minimaal één symbool bevatten.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "Het {attribute} moet minimaal één hoofdletter en één kleine letter bevatten.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "Het {attribute} is aangetroffen in een datalek. Geef een ander {attribute}.",
        "The given data was invalid.": "De gegeven data was ongeldig.",
        "The request quote has been filled in. See all details below:": "De offerteaanvraag is ingevuld. Zie alle details hieronder:",
        "The vacancyform has been filled in. See all details below.": "Het vacatureformulier is ingevuld. Zie alle details hieronder.",
        "This password reset link will expire in {count} minutes.": "Deze link om je wachtwoord te herstellen verloopt over {count} minuten.",
        "Title": "Titel",
        "title": "titel",
        "to": "tot",
        "Toggle navigation": "Schakel navigatie",
        "Too Many Requests": "Te veel serververzoeken",
        "Type": "Type",
        "Type Request": "Type aanvraag",
        "Type request": "Type aanvraag",
        "Unauthorized": "Onbevoegd",
        "Unfortunately, the page you are looking for is not (no longer) available. We apologize for the inconvenience.": "Helaas is de pagina die u zoekt niet (meer) beschikbaar. Onze excuses voor het ongemak.",
        "Upload C.V": "Upload C.V.",
        "Upload C.V *": "Upload C.V *",
        "Uploaded image ad background for card": "Geüploade afbeelding als achtergrond voor kaart",
        "Uploaded image next to text": "Geüploade afbeelding naast tekst",
        "url": "url",
        " vacancies": "vacatures",
        "Vacancies": "vacatures",
        "vacancies": "vacatures",
        "vacancy": "vacature",
        "Variants": "Varianten",
        "Verify Email Address": "Verifieer e-mailadres",
        "we are looking for?": "waar wij naar zoeken?",
        "Web Page": "Bewerk pagina",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.": "We hebben ons best gedaan, maar het lijkt erop dat de door u opgevraagde pagina niet (meer) bestaat of dat deze is verplaatst.",
        "We have received your message. One of our employees will contact you as soon as possible.": "We hebben uw bericht ontvangen. Een van onze medewerkers zal zo spoedig mogelijk contact met u opnemen.",
        "We have received your message and will contact you within 2 business days.": "Wij hebben uw bericht ontvangen en nemen binnen 2 werkdagen contact met u op.",
        "Whoops!": "Oeps!",
        "Yes": "Ja",
        "You are receiving this email because we received a password reset request for your account.": "Je ontvangt deze e-mail omdat we een wachtwoordherstel verzoek hebben ontvangen voor je account.",
        "You can find the mobile car wash at various supermarkets in the Netherlands and Belgium. The mobile car wash can be found at Albert Heijn, Jumbo, Coop, Makro, Spar, Plus, Carrefour Market, Delhaize, Colruyt Retail Partners, Poiesz Supermarkets, DekaMarkt and Alvo, among others.": "U vindt de mobiele wasstraat bij verschillende supermarkten in Nederland en België. De mobiele wasstraat is onder andere te vinden bij Albert Heijn, Jumbo, Coop, Makro, Spar, Plus, Carrefour Market, Delhaize, Colruyt Retail Partners, Poiesz Supermarkten, DekaMarkt en Alvo.",
        "Your message has been sent successfully!": "Uw bericht is succesvol verzonden!",
        "Youtube icon": "Youtube-pictogram",
        "Youtube Video ID": "Youtube Video ID",
        "Zip": "Postcode",
        "zip": "Postcode",
        "Zip*": "Postcode*",
        "validation": {
            "accepted": "{Attribute} moet worden geaccepteerd.",
            "accepted_if": "{Attribute} moet worden geaccepteerd als {other} {value} is.",
            "active_url": "{Attribute} is geen geldige URL.",
            "after": "{Attribute} moet een datum na {date} zijn.",
            "after_or_equal": "{Attribute} moet een datum na of gelijk aan {date} zijn.",
            "alpha": "{Attribute} mag alleen letters bevatten.",
            "alpha_dash": "{Attribute} mag alleen letters, nummers, underscores (_) en streepjes (-) bevatten.",
            "alpha_num": "{Attribute} mag alleen letters en nummers bevatten.",
            "array": "{Attribute} moet geselecteerde elementen bevatten.",
            "before": "{Attribute} moet een datum vóór {date} zijn.",
            "before_or_equal": "{Attribute} moet een datum vóór of gelijk aan {date} zijn.",
            "between": {
                "array": "{Attribute} moet tussen {min} en {max} waardes bevatten.",
                "file": "{Attribute} moet tussen {min} en {max} kilobytes zijn.",
                "numeric": "{Attribute} moet tussen {min} en {max} zijn.",
                "string": "{Attribute} moet tussen {min} en {max} karakters zijn."
            },
            "boolean": "{Attribute} moet ja of nee zijn.",
            "confirmed": "Bevestiging van {attribute} komt niet overeen.",
            "current_password": "Huidig wachtwoord is onjuist.",
            "date": "{Attribute} moet een datum bevatten.",
            "date_equals": "{Attribute} moet een datum gelijk aan {date} zijn.",
            "date_format": "{Attribute} voldoet niet aan het formaat {format}.",
            "declined": "{Attribute} moet afgewezen worden.",
            "declined_if": "{Attribute} moet afgewezen worden wanneer {other} gelijk is aan {value}.",
            "different": "{Attribute} en {other} moeten verschillend zijn.",
            "digits": "{Attribute} moet bestaan uit {digits} cijfers.",
            "digits_between": "{Attribute} moet bestaan uit minimaal {min} en maximaal {max} cijfers.",
            "dimensions": "{Attribute} heeft geen geldige afmetingen voor afbeeldingen.",
            "distinct": "{Attribute} heeft een dubbele waarde.",
            "doesnt_end_with": "The {attribute} may not end with one of the following: {values}.",
            "doesnt_start_with": "{Attribute} mag niet beginnen met één van de volgende waarden: {values}.",
            "email": "{Attribute} is geen geldig e-mailadres.",
            "ends_with": "{Attribute} moet met één van de volgende waarden eindigen: {values}.",
            "enum": "Gekozen {attribute} is ongeldig.",
            "exists": "{Attribute} bestaat niet.",
            "file": "{Attribute} moet een bestand zijn.",
            "filled": "{Attribute} is verplicht.",
            "gt": {
                "array": "{Attribute} moet meer dan {value} waardes bevatten.",
                "file": "{Attribute} moet groter zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet groter zijn dan {value}.",
                "string": "{Attribute} moet meer dan {value} tekens bevatten."
            },
            "gte": {
                "array": "{Attribute} moet {value} of meer waardes bevatten.",
                "file": "{Attribute} moet groter of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet groter of gelijk zijn aan {value}.",
                "string": "{Attribute} moet minimaal {value} tekens bevatten."
            },
            "image": "{Attribute} moet een afbeelding zijn.",
            "in": "{Attribute} is ongeldig.",
            "in_array": "{Attribute} bestaat niet in {other}.",
            "integer": "{Attribute} moet een getal zijn.",
            "ip": "{Attribute} moet een geldig IP-adres zijn.",
            "ipv4": "{Attribute} moet een geldig IPv4-adres zijn.",
            "ipv6": "{Attribute} moet een geldig IPv6-adres zijn.",
            "json": "{Attribute} moet een geldige JSON-string zijn.",
            "lowercase": "The {attribute} must be lowercase.",
            "lt": {
                "array": "{Attribute} moet minder dan {value} waardes bevatten.",
                "file": "{Attribute} moet kleiner zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner zijn dan {value}.",
                "string": "{Attribute} moet minder dan {value} tekens bevatten."
            },
            "lte": {
                "array": "{Attribute} moet {value} of minder waardes bevatten.",
                "file": "{Attribute} moet kleiner of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner of gelijk zijn aan {value}.",
                "string": "{Attribute} moet maximaal {value} tekens bevatten."
            },
            "mac_address": "{Attribute} moet een geldig MAC-adres zijn.",
            "max": {
                "array": "{Attribute} mag niet meer dan {max} waardes bevatten.",
                "file": "{Attribute} mag niet meer dan {max} kilobytes zijn.",
                "numeric": "{Attribute} mag niet hoger dan {max} zijn.",
                "string": "{Attribute} mag niet uit meer dan {max} tekens bestaan."
            },
            "max_digits": "The {attribute} must not have more than {max} digits.",
            "mimes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "mimetypes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "min": {
                "array": "{Attribute} moet minimaal {min} waardes bevatten.",
                "file": "{Attribute} moet minimaal {min} kilobytes zijn.",
                "numeric": "{Attribute} moet minimaal {min} zijn.",
                "string": "{Attribute} moet minimaal {min} tekens zijn."
            },
            "min_digits": "The {attribute} must have at least {min} digits.",
            "multiple_of": "{Attribute} moet een veelvoud van {value} zijn.",
            "not_in": "{Attribute} is ongeldig.",
            "not_regex": "Het formaat van {attribute} is ongeldig.",
            "numeric": "{Attribute} moet een getal zijn.",
            "password": {
                "letters": "{Attribute} moet minimaal één letter bevatten.",
                "mixed": "{Attribute} moet minimaal één kleine letter en één hoofdletter bevatten.",
                "numbers": "{Attribute} moet minimaal één cijfer bevatten.",
                "symbols": "{Attribute} moet minimaal één vreemd teken bevatten.",
                "uncompromised": "Het opgegeven {attribute} komt voor in een datalek. Kies een ander {attribute}."
            },
            "present": "{Attribute} moet aanwezig zijn.",
            "prohibited": "{Attribute} is niet toegestaan.",
            "prohibited_if": "{Attribute} is niet toegestaan indien {other} gelijk is aan {value}.",
            "prohibited_unless": "{Attribute} is niet toegestaan tenzij {other} gelijk is aan {values}.",
            "prohibits": "{Attribute} is niet toegestaan in combinatie met {other}.",
            "regex": "Het formaat van {attribute} is ongeldig.",
            "required": "{Attribute} is verplicht.",
            "required_array_keys": "{Attribute} moet waardes bevatten voor {values}.",
            "required_if": "{Attribute} is verplicht indien {other} gelijk is aan {value}.",
            "required_if_accepted": "The {attribute} field is required when {other} is accepted.",
            "required_unless": "{Attribute} is verplicht tenzij {other} gelijk is aan {values}.",
            "required_with": "{Attribute} is verplicht in combinatie met {values}.",
            "required_with_all": "{Attribute} is verplicht in combinatie met {values}.",
            "required_without": "{Attribute} is verplicht als {values} niet ingevuld is.",
            "required_without_all": "{Attribute} is verplicht als {values} niet ingevuld zijn.",
            "same": "{Attribute} en {other} moeten overeenkomen.",
            "size": {
                "array": "{Attribute} moet {size} waardes bevatten.",
                "file": "{Attribute} moet {size} kilobytes groot zijn.",
                "numeric": "{Attribute} moet {size} zijn.",
                "string": "{Attribute} moet {size} tekens zijn."
            },
            "starts_with": "{Attribute} moet beginnen met een van de volgende: {values}.",
            "string": "{Attribute} moet een tekst zijn.",
            "timezone": "{Attribute} moet een geldige tijdzone zijn.",
            "unique": "{Attribute} is al in gebruik.",
            "uploaded": "Het uploaden van {attribute} is mislukt.",
            "uppercase": "The {attribute} must be uppercase.",
            "url": "{Attribute} moet een geldige URL zijn.",
            "uuid": "{Attribute} moet een geldige UUID zijn.",
            "attributes": {
                "address": "adres",
                "age": "leeftijd",
                "amount": "bedrag",
                "area": "regio",
                "available": "beschikbaar",
                "birthday": "verjaardag",
                "body": "body",
                "city": "stad",
                "content": "inhoud",
                "country": "land",
                "created_at": "aangemaakt op",
                "creator": "maker",
                "current_password": "huidig wachtwoord",
                "date": "datum",
                "date_of_birth": "geboortedatum",
                "day": "dag",
                "deleted_at": "verwijderd op",
                "description": "omschrijving",
                "district": "wijk",
                "duration": "tijdsduur",
                "email": "e-mailadres",
                "excerpt": "uittreksel",
                "filter": "filter",
                "first_name": "voornaam",
                "gender": "geslacht",
                "group": "groep",
                "hour": "uur",
                "house_number": "huisnummer",
                "company_name": "Bedrijfsnaam",
                "place": "plaats",
                "zip": "postcode",
                "image": "afbeelding",
                "last_name": "achternaam",
                "lesson": "les",
                "line_address_1": "adresregel 1",
                "line_address_2": "adresregel 2",
                "message": "bericht",
                "middle_name": "tweede naam",
                "minute": "minuut",
                "mobile": "mobiel",
                "month": "maand",
                "name": "naam",
                "national_code": "landcode",
                "number": "nummer",
                "password": "wachtwoord",
                "password_confirmation": "wachtwoordbevestiging",
                "phone": "telefoonnummer",
                "photo": "foto",
                "postal_code": "postcode",
                "price": "prijs",
                "province": "provincie",
                "recaptcha_response_field": "recaptcha antwoordveld",
                "remember": "onthouden",
                "restored_at": "hersteld op",
                "result_text_under_image": "antwoord tekst onder afbeelding",
                "role": "rol",
                "second": "seconde",
                "sex": "geslacht",
                "short_text": "korte tekst",
                "size": "grootte",
                "state": "staat",
                "street": "straatnaam",
                "student": "student",
                "subject": "onderwerp",
                "teacher": "docent",
                "terms": "voorwaarden",
                "test_description": "test beschrijving",
                "test_locale": "test landinstelling",
                "test_name": "test naam",
                "text": "tekst",
                "time": "tijd",
                "title": "titel",
                "updated_at": "bijgewerkt op",
                "username": "gebruikersnaam",
                "year": "jaar"
            }
        },
        "routes": {
            "news": "nieuws",
            "vacancies": "vacatures"
        },
        "auth": {
            "failed": "Deze combinatie van e-mailadres en wachtwoord is niet geldig.",
            "password": "Wachtwoord is onjuist.",
            "throttle": "Te veel mislukte aanmeldpogingen. Probeer het nog eens over {seconds} seconden."
        },
        "pagination": {
            "next": "Volgende &raquo;",
            "previous": "&laquo; Vorige"
        },
        "passwords": {
            "reset": "Het wachtwoord van uw account is gewijzigd.",
            "sent": "We hebben een e-mail verstuurd met instructies om een nieuw wachtwoord in te stellen.",
            "throttled": "Gelieve even te wachten en het dan opnieuw te proberen.",
            "token": "Dit wachtwoordhersteltoken is niet geldig.",
            "user": "Geen gebruiker bekend met het e-mailadres."
        }
    }
}
